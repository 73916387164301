// ./PMenuGeneric.jsx or PMenu.js

import { 
	FaAngleDoubleLeft,
	FaAngleDoubleRight,
	FaAngleLeft,
	FaAngleRight,
	FaEllipsisH
  } from "react-icons/fa";
  import "./PMenu.css";
  
  // Controls the amount of quick context buttons around the current page
  const ctxAround = 3;
  const maxCtxInView = ctxAround * 2;
  
  /**
   * A generic pagination menu component.
   * @param {Number} currentPage - The current page index.
   * @param {Number} maxPages - The total number of pages.
   * @param {Function} setCurrentPage - Setter for currentPage.
   */
  export default function PaginationMenu({ currentPage, maxPages, setCurrentPage }) {
	
	const getEllipses = (idx) => {
	  return (
		<li key={`ellipsis-${idx}`}>
		  <span className="pg btn_gap">
			<FaEllipsisH />
		  </span>
		</li>
	  );
	};
  
	const handleJumpToPage = (pageIdx) => {
	  setCurrentPage(pageIdx);
	};
  
	const handleFirstPage = () => {
	  if (currentPage > 1) {
		setCurrentPage(1);
	  }
	};
  
	const handlePreviousPage = () => {
	  if (currentPage > 1) {
		setCurrentPage(currentPage - 1);
	  }
	};
  
	const handleNextPage = () => {
	  if (currentPage < maxPages) {
		setCurrentPage(currentPage + 1);
	  }
	};
  
	const handleLastPage = () => {
	  if (currentPage < maxPages) {
		setCurrentPage(maxPages);
	  }
	};
  
	const renderPageNumbers = () => {
	  const minPages = 1;
	  const minCtx = currentPage - ctxAround;
	  const maxCtx = currentPage + ctxAround;
  
	  let minIdx = minCtx > minPages ? minCtx : minPages;
	  let maxIdx = maxCtx < maxPages ? maxCtx : maxPages;
	  const pageNumbers = [];
  
	  // If total pages exceed maxCtxInView, we may need ellipses
	  if (maxPages > maxCtxInView) {
		// Adjust indices if total # in view < maxCtxInView
		const totalPagesInView = maxIdx - minIdx;
		if (totalPagesInView < maxCtxInView) {
		  const deltaNeeded = maxCtxInView - totalPagesInView;
		  if (maxIdx < maxPages) {
			maxIdx = Math.min(maxPages, maxIdx + deltaNeeded);
		  }
		  if (maxIdx - minIdx + 1 < maxCtxInView) {
			minIdx = Math.max(minPages, minIdx - (maxCtxInView - (maxIdx - minIdx + 1)));
		  }
		}
		if (minIdx > minPages) {
		  pageNumbers.push(getEllipses(minPages));
		}
	  } else {
		// If total pages <= maxCtxInView, just show them all
		minIdx = minPages;
		maxIdx = maxPages;
	  }
  
	  for (let i = minIdx; i <= maxIdx; i++) {
		pageNumbers.push(
		  <li key={i}>
			<span
			  className={`pg pg_button ${currentPage === i ? "selected" : ""}`}
			  onClick={() => handleJumpToPage(i)}
			>
			  <span className="pg-number">{i}</span>
			</span>
		  </li>
		);
	  }
	  if (maxPages > maxCtxInView && maxIdx < maxPages) {
		pageNumbers.push(getEllipses(maxPages));
	  }
	  return pageNumbers;
	};
  
	// Only render if there's more than 1 page
	if (maxPages <= 1) {
	  return null;
	}
  
	return (
	  <nav className="pagination_container d-flex flex-wrap justify-content-between align-items-center">
		<div className="big_skip left">
		  <span
			className={`pg_button ${currentPage === 1 ? "disabled" : ""}`}
			onClick={handleFirstPage}
		  >
			<FaAngleDoubleLeft /> <span className="pg-text">First Page</span>
		  </span>
		</div>
		<div className="small_skip left">
		  <span
			className={`pg_button ${currentPage === 1 ? "disabled" : ""}`}
			onClick={handlePreviousPage}
		  >
			<FaAngleLeft /> <span className="pg-text">Previous</span>
		  </span>
		</div>
		<ul className="pagination mb-0 col-4 d-flex justify-content-center">
		  {renderPageNumbers()}
		</ul>
		<div className="small_skip right">
		  <span
			className={`pg_button ${currentPage === maxPages ? "disabled" : ""}`}
			onClick={handleNextPage}
		  >
			<span className="pg-text">Next</span> <FaAngleRight />
		  </span>
		</div>
		<div className="big_skip right">
		  <span
			className={`pg_button ${currentPage === maxPages ? "disabled" : ""}`}
			onClick={handleLastPage}
		  >
			<span className="pg-text">Last Page</span> <FaAngleDoubleRight />
		  </span>
		</div>
		{/* Debugging can go here, if needed */}
	  </nav>
	);
  }
  