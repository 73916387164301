import React, { useState } from "react";
import { FaUpload, FaSpinner, FaTimes } from "react-icons/fa";

// Simple Modal Component
const DeleteConfirmDialog = ({ isOpen, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-4">Are you sure?</h3>
        <p className="text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

function ContentStructureStep({
  sections,
  expandedSection,
  editingSectionId,
  editingSubsectionId,
  onAddSection,
  onAddSubsection,
  onUpdateSectionTitle,
  onUpdateSubsectionTitle,
  onVideoChange,
  onExpandSection,
  onEditingSectionIdChange,
  onEditingSubsectionIdChange,
  onDeleteSection,
  onDeleteSubsection,
  onPrevious,
  onNext,
}) {
  const [uploadingSubsection, setUploadingSubsection] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleVideoChangeWithSpinner = (e, sectionIndex, subsectionIndex) => {
    setUploadingSubsection({ sectionIndex, subsectionIndex });
    onVideoChange(e, sectionIndex, subsectionIndex).finally(() =>
      setUploadingSubsection(null)
    );
  };

  const handleDelete = () => {
    if (itemToDelete) {
      if (itemToDelete.type === 'section') {
        onDeleteSection(itemToDelete.sectionIndex);
      } else {
        onDeleteSubsection(itemToDelete.sectionIndex, itemToDelete.subsectionIndex);
      }
    }
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  return (
    <div className="border border-gray-300 rounded-lg bg-white p-6 mb-6">
      <h2 className="text-left text-lg font-bold text-gray-800">
        Course Content
      </h2>
      <p className="text-sm text-gray-600 mb-4">
        Add sections and subsections to organize your course content. Each
        section can have up to 10 subsections, and you can create a maximum of
        20 sections.
      </p>

      <div>
        {sections.map((section, sectionIndex) => (
          <div
            key={section.id}
            className="mb-3 border border-gray-300 rounded-lg bg-white"
          >
            <div className="flex justify-between items-center p-4 border-b border-gray-300">
              <div 
                className="flex-1 cursor-pointer hover:bg-gray-50"
                onClick={() => onExpandSection(expandedSection === sectionIndex ? null : sectionIndex)}
              >
                <div className="flex flex-col">
                  <h3 className="font-semibold text-gray-800">
                    {editingSectionId === section.id ? (
                      <input
                        type="text"
                        value={section.title}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          onUpdateSectionTitle(section.id, e.target.value)
                        }
                        onBlur={() =>
                          onUpdateSectionTitle(section.id, section.title, true)
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onUpdateSectionTitle(section.id, section.title, true);
                          }
                        }}
                        autoFocus
                        className="border border-gray-300 rounded-md px-2 py-1 w-[200px]"
                      />
                    ) : (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditingSectionIdChange(section.id);
                        }}
                        className="hover:bg-gray-100 rounded py-1 cursor-text"
                      >
                        Section {sectionIndex + 1}: {section.title}
                      </span>
                    )}
                  </h3>
                  <span className="text-sm text-gray-600">
                    {section.subsections.length}/10 subsections |{" "}
                    {section.subsections.reduce(
                      (total, sub) => total + (sub.duration || 0),
                      0
                    )}{" "}
                    min total
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteConfirm(true);
                    setItemToDelete({ type: 'section', sectionIndex });
                  }}
                  className="text-gray-500 hover:text-red-500 p-1"
                >
                  <FaTimes size={16} />
                </button>
                <span className="text-gray-500">
                  {expandedSection === sectionIndex ? "▼" : "▲"}
                </span>
              </div>
            </div>

            {expandedSection === sectionIndex && (
              <div className="bg-gray-50 p-4">
                {section.subsections.map((subsection, subsectionIndex) => (
                  <div
                    key={subsection.id}
                    className="flex flex-col md:items-center justify-between border-b border-gray-300 p-2 bg-white rounded relative"
                  >
                    <button
                      onClick={() => {
                        setShowDeleteConfirm(true);
                        setItemToDelete({ type: 'subsection', sectionIndex, subsectionIndex });
                      }}
                      className="absolute top-2 right-2 text-gray-500 hover:text-red-500"
                    >
                      <FaTimes size={14} />
                    </button>

                    <div className="flex-1 flex flex-col md:flex-row md:items-center md:gap-4">
                      {editingSubsectionId === subsection.id ? (
                        <input
                          type="text"
                          value={subsection.title}
                          onChange={(e) =>
                            onUpdateSubsectionTitle(
                              sectionIndex,
                              subsectionIndex,
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            onUpdateSubsectionTitle(
                              sectionIndex,
                              subsectionIndex,
                              subsection.title,
                              true
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              onUpdateSubsectionTitle(
                                sectionIndex,
                                subsectionIndex,
                                subsection.title,
                                true
                              );
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                          autoFocus
                          className="border border-gray-300 rounded-md w-[200px]"
                        />
                      ) : (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditingSubsectionIdChange(subsection.id);
                          }}
                          className="hover:bg-gray-100 rounded cursor-text font-medium text-gray-800"
                        >
                          {subsectionIndex + 1}. {subsection.title}
                        </span>
                      )}
                      <span className="text-sm text-gray-600 md:ml-2">
                        {subsection.duration || 0} min
                      </span>
                    </div>

                    {/* Video Upload */}
                    <div className="my-2 md:my-0 md:ml-4">
                      <label
                        htmlFor={`video-upload-${sectionIndex}-${subsectionIndex}`}
                        className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-5 bg-gray-100 hover:bg-gray-200 transition cursor-pointer min-h-[120px]"
                      >
                        {uploadingSubsection &&
                        uploadingSubsection.sectionIndex === sectionIndex &&
                        uploadingSubsection.subsectionIndex === subsectionIndex ? (
                          <div className="flex flex-col items-center">
                            <FaSpinner
                              size={24}
                              className="animate-spin text-gray-500 mb-2"
                            />
                            <p className="text-sm text-gray-600">
                              Uploading...
                            </p>
                          </div>
                        ) : subsection.videoFile ? (
                          <>
                            <p className="text-gray-700">
                              {subsection.videoFile.name}
                            </p>
                            <button
                              onClick={() =>
                                onVideoChange(
                                  "remove",
                                  sectionIndex,
                                  subsectionIndex
                                )
                              }
                              className="mt-2 text-red-500 text-sm hover:underline"
                            >
                              Remove Video
                            </button>
                          </>
                        ) : (
                          <>
                            <FaUpload
                              size={24}
                              className="text-gray-500 mb-2"
                            />
                            <p className="text-sm text-gray-600">
                              Click to upload a video
                            </p>
                          </>
                        )}
                        <input
                          type="file"
                          id={`video-upload-${sectionIndex}-${subsectionIndex}`}
                          accept="video/mp4,video/quicktime,video/x-msvideo"
                          onChange={(e) =>
                            handleVideoChangeWithSpinner(
                              e,
                              sectionIndex,
                              subsectionIndex
                            )
                          }
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                ))}

                {section.subsections.length < 10 && (
                  <button
                    onClick={() => onAddSubsection(sectionIndex)}
                    className="w-full py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200 transition"
                  >
                    Add Subsection
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Delete confirmation dialog */}
      <DeleteConfirmDialog
        isOpen={showDeleteConfirm}
        message={
          itemToDelete?.type === 'section'
            ? "This will delete the entire section and all its subsections. This action cannot be undone."
            : "This will delete the subsection and its content. This action cannot be undone."
        }
        onConfirm={handleDelete}
        onCancel={() => {
          setShowDeleteConfirm(false);
          setItemToDelete(null);
        }}
      />

      {sections.length < 20 && (
        <div className="flex justify-end">
          <button
            onClick={onAddSection}
            className="bg-gradient-to-r from-green-500 to-green-700 text-white font-semibold rounded-full px-3 py-2.5 shadow-lg flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Add Section
          </button>
        </div>
      )}

      <div className="flex justify-between mt-4">
        <button
          onClick={onPrevious}
          className="bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition"
        >
          Back
        </button>
        <button
          onClick={onNext}
          className="bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ContentStructureStep;