import React, { useState, useEffect, useRef } from "react";
import { useLoadScript } from "@react-google-maps/api";

// Define which Google Maps libraries are needed (Places API)
const libraries = ["places"];

// Predefined mapping of common abbreviations to full school names
const popularSchools = {
  UCLA: "University of California, Los Angeles",
  USC: "University of Southern California",
  MIT: "Massachusetts Institute of Technology",
  NYU: "New York University",
  UCB: "University of California, Berkeley",
  UCSC: "University of California, Santa Cruz",
};

const AutocompleteInput = ({ placeholder, className, onSelection }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Use your API key
    libraries,
  });

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const autocompleteRef = useRef(null); // Create a ref for the autocomplete component

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);

    if (onSelection) onSelection(input);

    // Check if the input matches any popular school abbreviation
    const abbreviationMatch = Object.keys(popularSchools).find(
      (abbr) => abbr.toLowerCase() === input.toLowerCase()
    );

    if (abbreviationMatch) {
      // If a match is found, manually suggest the full school name (e.g., UCLA -> University of California, Los Angeles)
      setSuggestions([{ mainText: popularSchools[abbreviationMatch] }]);
      return;
    }

    // If no abbreviation match, call Google Places API
    if (input.length > 2) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input,
          types: ["establishment"], // Establishments including universities/colleges
          componentRestrictions: { country: "us" }, // Optionally restrict to a specific country
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Filter to only include universities, colleges, or higher education institutions
            const filteredPredictions = predictions
              .filter(
                (prediction) =>
                  prediction.description.toLowerCase().includes("university") ||
                  prediction.description.toLowerCase().includes("college") ||
                  prediction.description.toLowerCase().includes("institute") ||
                  prediction.description
                    .toLowerCase()
                    .includes("school of technology") ||
                  prediction.description
                    .toLowerCase()
                    .includes("higher education")
              )
              .map((prediction) => {
                let name = prediction.structured_formatting.main_text;

                // Remove unwanted terms like "Library", "Building", etc.
                const unwantedTerms = [
                  "Library",
                  "Building",
                  "Center",
                  "Hall",
                  "Shopping",
                  "Middle",
                  "Elementary",
                ];
                unwantedTerms.forEach((term) => {
                  name = name
                    .replace(new RegExp(`\\s*${term}\\s*`, "gi"), "")
                    .trim();
                });

                return { mainText: name };
              });

            setSuggestions(filteredPredictions);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.mainText); // Only set the refined school name
    if (onSelection) onSelection(suggestion.mainText); // Pass the selected suggestion to the parent component
    setSuggestions([]);
  };

  // Function to close the dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (
      autocompleteRef.current &&
      !autocompleteRef.current.contains(event.target)
    ) {
      setSuggestions([]); // Close the dropdown when clicking outside
    }
  };

  // Add click event listener when component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div
      ref={autocompleteRef}
      className="autocomplete-container"
      style={{ position: "relative", width: "100%" }}
    >
      {/* Apply the default class name for the input to keep styling consistent */}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={className} // Keep the class to apply default input styles
      />
      {suggestions.length > 0 && (
        <ul
          style={{
            position: "absolute",
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid black",
            maxHeight: "200px", // Set the max height for the dropdown
            overflowY: "auto", // Enable scrolling when content exceeds max height
            width: "100%",
            marginTop: "4px",
            listStyleType: "none",
            padding: "0",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "10px",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: "10px",
                cursor: "pointer",
                borderBottom:
                  index !== suggestions.length - 1 ? "2px solid black" : "none",
                backgroundColor: "white",
                transition: "background-color 0.3s ease",
                borderRadius: "8px",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "orange")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "white")}
            >
              {suggestion.mainText} {/* Show only the filtered school name */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;
