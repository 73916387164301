import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./SignupPage.css";
import logo from "../../../assets/logo.PNG";
// import GoogleSignIn from "../Login/GoogleSignIn"; // Import the GoogleSignIn component
import AutocompleteInput from "../../AutoComplete/AutoCompleteInput"; // For Google Places (school input)
import KnowledgeGraphAutocomplete from "../../AutoComplete/KnowledgeGraphAutoComplete";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaTimesCircle,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";

const SignupPage = ({ onSignup }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (location.state && location.state.role) {
      setRole(location.state.role);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Validate the input fields
  const validateField = (field, value) => {
    switch (field) {
      case "username": {
        // Must be 3-32 characters, alphanumeric or underscore only
        const usernameRegex = /^[a-zA-Z0-9_]{3,32}$/;
        if (!usernameRegex.test(value)) {
          return "Username must be 3–32 characters long and can only include letters, numbers, and underscores.";
        }
        return "";
      }

      case "email": {
        // Standard email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return "Invalid email format.";
        }
        return "";
      }

      case "password": {
        /*
          Must be 8-32 characters, include at least one uppercase, one lowercase,
          one digit, one special character, and contain no spaces
        */
        const passwordRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s).{8,32}$/;
        if (!passwordRegex.test(value)) {
          return "Password must be 8–32 chars, include upper/lowercase, digit, special character, and no spaces.";
        }
        return "";
      }
      default:
        return "";
    }
  };

  // Handle input change
  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });

    // Only run validation if it's a known field
    if (["username", "email", "password"].includes(field)) {
      setErrors({ ...errors, [field]: validateField(field, value) });
    } else {
      setErrors({ ...errors, [field]: "" });
    }
  };

  // Handle role selection
  const handleRoleSelection = (selectedRole) => {
    setRole(selectedRole.toLowerCase());
    setFormData({});
    setStep(2);
  };

  // Handle back button
  const handleBack = () => {
    if (step === 2) setStep(1);
  };

  const RequiredField = ({
    value,
    onChange,
    placeholder,
    isRequired = false,
    component: Component = "input", // default to <input />
    ...props
  }) => {
    return (
      <div className="input-wrapper">
        <div className="input-container">
          <Component
            className="signup-input"
            placeholder={placeholder}
            value={value || ""}
            onChange={(e) =>
              onChange(typeof e.target !== "undefined" ? e.target.value : e)
            }
            {...props}
          />
          {isRequired && !value && <span className="required-asterisk">*</span>}
        </div>
      </div>
    );
  };

  const handleSignup = async (event) => {
    event.preventDefault();

    // Check if all required fields are filled
    const newErrors = {};
    if (!name) newErrors.username = "Username is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!password) newErrors.password = "Password is required.";

    // Check role-specific fields
    if (role === "student") {
      if (!formData.school) newErrors.school = "School is required.";
      if (!formData.major) newErrors.major = "Major is required.";
    } else if (role === "educator") {
      if (!formData.plan_to_teach)
        newErrors.plan_to_teach = "Plan to teach is required.";
      if (!formData.school_attended)
        newErrors.school_attended = "School attended is required.";
    } else if (role === "organization") {
      if (!formData.organization_name)
        newErrors.organization_name = "Organization name is required.";
      if (!formData.industry) newErrors.industry = "Industry is required.";
    }

    // If there are errors, update the errors state and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      alert("Please fill out all required fields.");
      return;
    }

    // Create the payload
    const payload = {
      username: name,
      email,
      password,
      role,
    };

    if (role === "student") {
      payload.school = formData.school;
      payload.major = formData.major;
      payload.employer = formData.employer;
      payload.job_title = formData.job_title;
      payload.aspiration = formData.aspiration;
    } else if (role === "educator") {
      payload.plan_to_teach = formData.plan_to_teach;
      payload.school_attended = formData.school_attended;
      payload.employer = formData.employer;
      payload.job_title = formData.job_title;
    } else if (role === "organization") {
      payload.organization_name = formData.organization_name;
      payload.industry = formData.industry;
      payload.primary_service = formData.primary_service;
      payload.job_title = formData.job_title;
    }

    // Submit the data to the backend
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/register`,
        payload,
        { withCredentials: true } // Allows setting of the cookies after registration
      );

      // Add a check for response and response.data
      if (response && response.data) {
        onSignup(response.data); // Call the onSignup function to update the state
      } else {
        throw new Error("Server returned an invalid response");
      }
    } catch (error) {
      console.error(
        "Error creating account:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // Role selection handler
  const renderRoleSpecificForm = () => {
    return (
      <div className="signup-role-form">
        {role === "student" && (
          <>
            <div className="input-wrapper">
              <div className="input-container">
                <AutocompleteInput
                  placeholder="Where do you go to school?"
                  className="signup-input"
                  onSelection={(school) => handleInputChange("school", school)}
                />
                {!formData.school && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <KnowledgeGraphAutocomplete
                  placeholder="What are you studying?"
                  className="signup-input"
                  onSelection={(major) => handleInputChange("major", major)}
                  type="major"
                />
                {!formData.major && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <KnowledgeGraphAutocomplete
                  placeholder="Where do you work?"
                  className="signup-input"
                  onSelection={(employer) =>
                    handleInputChange("employer", employer)
                  }
                />
                {!formData.employer && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <KnowledgeGraphAutocomplete
                  placeholder="What do you do?"
                  className="signup-input"
                  onSelection={(jobTitle) =>
                    handleInputChange("job_title", jobTitle)
                  }
                  type="job"
                />
                {!formData.job_title && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <input
                  placeholder="What are you looking to learn about?"
                  className="signup-input"
                  value={formData.aspiration || ""}
                  onChange={(e) =>
                    handleInputChange("aspiration", e.target.value)
                  }
                />
                {!formData.aspiration && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>
          </>
        )}

        {role === "educator" && (
          <>
            <div className="input-wrapper">
              <div className="input-container">
                <input
                  type="text"
                  placeholder="What do you plan to teach?"
                  className="signup-input"
                  value={formData.plan_to_teach || ""}
                  onChange={(e) =>
                    handleInputChange("plan_to_teach", e.target.value)
                  }
                />
                {!formData.plan_to_teach && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <AutocompleteInput
                  placeholder="Where did you go to school?"
                  className="signup-input"
                  onSelection={(school_attended) =>
                    handleInputChange("school_attended", school_attended)
                  }
                />
                {!formData.school_attended && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <KnowledgeGraphAutocomplete
                  placeholder="Where do you work?"
                  className="signup-input"
                  onSelection={(employer) =>
                    handleInputChange("employer", employer)
                  }
                />
                {!formData.employer && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <KnowledgeGraphAutocomplete
                  placeholder="What do you do?"
                  className="signup-input"
                  onSelection={(jobTitle) =>
                    handleInputChange("job_title", jobTitle)
                  }
                  type="job"
                />
                {!formData.job_title && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>
          </>
        )}

        {role === "organization" && (
          <>
            <div className="input-wrapper">
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Name of your Organization"
                  className="signup-input"
                  value={formData.organization_name || ""}
                  onChange={(e) =>
                    handleInputChange("organization_name", e.target.value)
                  }
                />
                {!formData.organization_name && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <KnowledgeGraphAutocomplete
                  placeholder="What industry are you in?"
                  className="signup-input"
                  onSelection={(industry) =>
                    handleInputChange("industry", industry)
                  }
                />
                {!formData.industry && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Who do you serve primarily?"
                  className="signup-input"
                  value={formData.primary_service || ""}
                  onChange={(e) =>
                    handleInputChange("primary_service", e.target.value)
                  }
                />
                {!formData.primary_service && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-container">
                <KnowledgeGraphAutocomplete
                  placeholder="What is your role?"
                  className="signup-input"
                  onSelection={(jobTitle) =>
                    handleInputChange("job_title", jobTitle)
                  }
                  type="job"
                />
                {!formData.job_title && (
                  <span className="required-asterisk">*</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  // 1) Renders dynamic content or text on the left side, depending on step & role
  const renderLeftPanel = () => {
    if (step === 1) {
      return (
        <div className="left-content">
          <h1>Welcome!</h1>
          <p>Pick a role to get started on your learning journey.</p>
        </div>
      );
    }

    if (step === 2) {
      // Customize text/images for each role
      switch (role) {
        case "student":
          return (
            <div className="left-content">
              <h1>Learn, grow, and achieve your goals with Archi.</h1>
            </div>
          );
        case "educator":
          return (
            <div className="left-content">
              <h1>Guide and inspire learners with Archi.</h1>
            </div>
          );
        case "organization":
          return (
            <div className="left-content">
              <h1>Support education and growth with Archi.</h1>
            </div>
          );
        default:
          return (
            <div className="left-content">
              <h1>Complete Your Signup</h1>
              <p>Enter your information on the right.</p>
            </div>
          );
      }
    }

    // Fallback
    return null;
  };

  return (
    <div className="signup-page">
      {/* Left Side */}
      <div className="signup-left">
        <img src={logo} alt="Logo" className="signup-form-logo-image" />
        {renderLeftPanel()}
        <div className="signup-terms-text">
          <p>
            By signing up for Archi, you agree to our{" "}
            <a href="#" className="terms-link">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="#" className="privacy-link">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>

      {/* Right Side (same existing form) */}
      <div className="signup-right">
        <div className="signup-form-section">
          {step === 1 && (
            <div className="signup-role-selection">
              <h1>Sign Up</h1>
              <h2>Join for free as</h2>
              <div className="role-options">
                <div
                  className="role-option"
                  onClick={() => handleRoleSelection("student")}
                >
                  <span role="img" aria-label="student">
                    🎓
                  </span>
                  <div className="role-option-text">
                    <h3>Student</h3>
                    <p>
                      For learners looking to connect and grow academically.
                    </p>
                  </div>
                </div>
                <div
                  className="role-option"
                  onClick={() => handleRoleSelection("educator")}
                >
                  <span role="img" aria-label="educator">
                    📚
                  </span>
                  <div className="role-option-text">
                    <h3>Educator</h3>
                    <p>For teachers and mentors who inspire learning.</p>
                  </div>
                </div>
                <div
                  className="role-option"
                  onClick={() => handleRoleSelection("organization")}
                >
                  <span role="img" aria-label="organization">
                    🏢
                  </span>
                  <div className="role-option-text">
                    <h3>Organization</h3>
                    <p>For organizations supporting education and growth.</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <form className="signup-form" onSubmit={handleSignup}>
              <button
                type="button"
                className="signup-back-button"
                onClick={handleBack}
              >
                <FaArrowLeft style={{ marginRight: "0.5rem" }} /> Back
              </button>
              <h1>Sign Up</h1>
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Username"
                  className="signup-input"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    handleInputChange("username", e.target.value); // Validate dynamically
                  }}
                />
                {/* Render asterisk only if there's an error */}
                {!name && <span className="required-asterisk">*</span>}
                {errors.username && (
                  <p className="error-text">{errors.username}</p>
                )}
              </div>

              <div className="input-wrapper">
                <input
                  type="email"
                  placeholder="Email"
                  className="signup-input"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleInputChange("email", e.target.value); // Validate dynamically
                  }}
                />
                {/* Render asterisk only if there's an error */}
                {!email && <span className="required-asterisk">*</span>}
                {errors.email && <p className="error-text">{errors.email}</p>}
              </div>

              <div className="input-wrapper">
                <div className="password-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="signup-input"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      handleInputChange("password", e.target.value);
                    }}
                  />
                  {!password && <span className="required-asterisk">*</span>}
                  <button
                    type="button"
                    className="signup-toggle-password-button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.password && (
                  <p className="error-text">{errors.password}</p>
                )}
              </div>

              {renderRoleSpecificForm()}

              <div className="signup-terms-checkbox">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={formData.terms || false}
                  onChange={(e) => handleInputChange("terms", e.target.checked)}
                />
                <label htmlFor="terms">
                  I agree to the Terms of Service and Privacy Policy
                </label>
              </div>

              <button type="submit" className="signup-submit-button">
                Create Your Account
              </button>
            </form>
          )}

          <div className="signup-login-redirect">
            <p>
              <button
                type="button"
                className="login-redirect-button"
                onClick={() => navigate("/login")}
              >
                Already have an account?
              </button>
            </p>
          </div>

          {step === 1}
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
