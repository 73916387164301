import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaStar, FaShare, FaCaretDown, FaLock, FaUnlock } from "react-icons/fa";
import taxios from "../../util/token_refresh_hook";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import "./CoursesPage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import TopNavBar from "../HomePage/TopNavBar/TopNavBar";
import CommunitySidebar from "../HomePage/CommunitySidebar/CommunitySidebar";
import PaginationMenu from "../HomePage/Pagination/PMenuGeneric";

const placeholderImage = "https://via.placeholder.com/50";

const CoursesPage = ({ toggleSidebar, sidebarOpen, onLogout }) => {
  // ===== STATE =====
  const [ratingFilter, setRatingFilter] = useState("");
  const [topicFilter, setTopicFilter] = useState("");
  const [sortFilter, setSortFilter] = useState("newest");
  const [enrolledFilter, setEnrolledFilter] = useState("");
  const [courses, setCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]); // for showing lock/unlock

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  // Filtering dropdown states
  const [showDropdown, setShowDropdown] = useState(null);
  const [ratings, setRatings] = useState([]);
  const [topics, setTopics] = useState([]);

  // Loading, errors
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Roles, modals
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  // ===== EFFECTS =====
  // On mount: fetch user role, enrolled courses
  useEffect(() => {
    fetchUserRole();
    fetchEnrolledCourses();
    // eslint-disable-next-line
  }, []);

  // Whenever page changes (or filters, if you want), fetch courses
  useEffect(() => {
    fetchCourses(page);
    // eslint-disable-next-line
  }, [page]);

  // ===== API CALLS =====
  const fetchCourses = async (pageNumber) => {
    setIsLoading(true);
    try {
      // Example aggregator-based endpoint:
      // returns { payload: { courses, thisPage, pagination } }
      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses/by`,
        {
          // Make sure your backend expects these param names
          params: { page: pageNumber, coursesPerPage: 10 },
        }
      );

      const {
        courses: fetchedCourses = [],
        thisPage = {},
        pagination = {},
      } = response.data.payload || {};

      // We only store the current page's results (no infinite scroll)
      setCourses(fetchedCourses);

      // total # of pages (for the pagination menu)
      setMaxPages(pagination.pages || 1);

      // Gather unique ratings/topics for filter UI
      const uniqueRatings = [
        ...new Set(fetchedCourses.map((course) => Math.round(course.rating || 0))),
      ].sort((a, b) => a - b);

      const uniqueTopics = [
        ...new Set(fetchedCourses.map((course) => course.courseCategory || "")),
      ].sort();

      setRatings((prev) =>
        [...new Set([...prev, ...uniqueRatings])].sort((a, b) => a - b)
      );
      setTopics((prev) =>
        [...new Set([...prev, ...uniqueTopics])].sort()
      );
    } catch (error) {
      console.error("Error fetching courses:", error);
      setError("Failed to load courses.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserRole = async () => {
    try {
      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/role`
      );
      setUserRole(response.data.role);
    } catch (err) {
      console.error("Error fetching user role:", err);
      setError("Failed to fetch user role.");
    }
  };

  // If your backend enrolled-courses route also uses aggregator-based pagination,
  // it might return { payload: { courses, thisPage, pagination } }.
  // For now, we only need the IDs to highlight lock/unlock icons.
  const fetchEnrolledCourses = async () => {
    try {
      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses/enrolled`,
        { params: { page: 1, coursesPerPage: 100 } }
      );
      const { courses: enrolled = [] } = response.data.payload || {};
      setEnrolledCourses(enrolled.map((c) => c._id));
    } catch (err) {
      console.error("Error fetching enrolled courses:", err);
      setEnrolledCourses([]);
    }
  };

  // ===== HANDLERS =====
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  // Creates a PaymentIntent, then confirm w/ stripe, etc.
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsProcessing(true);

    try {
      // 1) Create PaymentIntent
      const response = await taxios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses/${selectedCourse._id}/join`
      );
      const { clientSecret } = response.data;

      // 2) Confirm card payment
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        toast.error("Payment form not loaded properly");
        return;
      }
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );
      if (error) {
        toast.error("Payment failed: " + error.message);
        return;
      }

      // 3) If payment succeeded, finalize enrollment
      if (paymentIntent.status === "succeeded") {
        await taxios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/courses/${selectedCourse._id}/join/confirm`,
          { paymentIntentId: paymentIntent.id }
        );
        // 4) UI updates
        setEnrolledCourses((prev) => [...prev, selectedCourse._id]);
        toast.success("Successfully enrolled in course!");
        setShowPaymentModal(false);
        setShowJoinModal(true);
        fetchEnrolledCourses(); // refresh
      }
    } catch (error) {
      if (error.response?.data?.error === "Already enrolled in this course") {
        toast.info("You are already enrolled in this course");
        setShowPaymentModal(false);
      } else {
        toast.error("Payment failed. Please try again.");
        console.error("Payment error:", error);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const openJoinModal = (course) => {
    setSelectedCourse(course);
    setShowJoinModal(true);
  };

  const openPaymentModal = (course) => {
    setSelectedCourse(course);
    setShowPaymentModal(true);
  };

  // Create course button => if user is educator or org, proceed
  const handleCreateCourse = () => {
    if (userRole === "educator" || userRole === "organization") {
      navigate("/course-creation");
    } else {
      setShowRoleModal(true);
    }
  };

  // Redirect to signup with given role
  const redirectToSignup = (role) => {
    navigate("/signup", { state: { role } });
  };

  // For share icon
  const handleShare = (courseTitle) => {
    const url = `https://example.com/courses/${courseTitle
      .replace(/\s+/g, "-")
      .toLowerCase()}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert(`Link to "${courseTitle}" copied: ${url}`);
      })
      .catch(() => {
        alert("Failed to copy the link.");
      });
  };

  // Filter dropdown toggles
  const handleFilterClick = (filterType) => {
    setShowDropdown((prev) => (prev === filterType ? null : filterType));
  };

  // Filter selection
  const handleFilterSelect = (filterType, value) => {
    if (filterType === "rating") setRatingFilter(value);
    if (filterType === "topic") setTopicFilter(value);
    if (filterType === "sort") setSortFilter(value);
    if (filterType === "enrolled") setEnrolledFilter(value);
    setShowDropdown(null);
  };

  // ===== FILTERING & SORTING LOCALLY =====
  const filteredCourses = courses
    .filter((course) => {
      // rating filter
      const ratingMatch = ratingFilter
        ? Math.round(course.rating) === parseInt(ratingFilter)
        : true;
      // topic filter
      const topicMatch = topicFilter
        ? course.courseCategory === topicFilter
        : true;
      // enrolled filter
      const enrolledMatch =
        enrolledFilter === "yes"
          ? enrolledCourses.includes(course._id)
          : enrolledFilter === "no"
          ? !enrolledCourses.includes(course._id)
          : true;

      return ratingMatch && topicMatch && enrolledMatch;
    })
    .sort((a, b) => {
      if (sortFilter === "newest") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (sortFilter === "oldest") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sortFilter === "rating") {
        return b.rating - a.rating;
      }
      return 0;
    });

  // ===== RENDER HELPERS =====
  const renderCourseList = () => {
    if (filteredCourses.length === 0 && !isLoading && !error) {
      return <div>No courses available.</div>;
    }

    return filteredCourses.map((course) => (
      <div
        key={course._id}
        className="courses-page-course-item"
        onClick={() => handleCourseClick(course._id)}
        style={{ cursor: "pointer" }}
      >
        {/* Thumbnail w/ Lock/Unlock */}
        <div className="courses-page-course-thumbnail" style={{ position: "relative" }}>
          <img
            src={
              course.courseThumbnailURL &&
              course.courseThumbnailURL.startsWith("http")
                ? course.courseThumbnailURL
                : "https://via.placeholder.com/150"
            }
            alt="Course Thumbnail"
          />
          {enrolledCourses.includes(course._id) ? (
            <FaUnlock
              className="unlock-icon"
              style={{ position: "absolute", top: "10px", right: "10px", color: "green" }}
            />
          ) : (
            <FaLock
              className="lock-icon"
              style={{ position: "absolute", top: "10px", right: "10px", color: "red" }}
            />
          )}
        </div>

        {/* Course Info */}
        <div className="courses-page-course-info">
          <div className="courses-page-course-details">
            <ProfilePicture
              src={course.creatorID.profilePicture || placeholderImage}
              alt={course.creatorID.username || "Anonymous"}
            />
            <div className="courses-page-title-creator-price">
              <h2>{course.courseTitle}</h2>
              <span className="courses-page-creator-name">
                {course.creatorID.username}
              </span>
              <span className="courses-page-price">
                {course.coursePrice === 0 ? "Free" : `$${course.coursePrice?.toFixed(2)}`}
              </span>
            </div>
          </div>

          {/* Rating */}
          <div className="courses-page-rating">
            {[...Array(5)].map((_, i) => (
              <FaStar
                key={i}
                color={i < Math.round(course.rating) ? "#ffc107" : "#e4e9f0"}
              />
            ))}
            <span>
              {course.rating?.toFixed(1)} ({course.ratingsCount || 0})
            </span>
          </div>

          {/* Join / Share */}
          <div className="courses-page-join-share-buttons">
            {enrolledCourses.includes(course._id) ? (
              <button className="enrolled-button" disabled>
                Enrolled
              </button>
            ) : (
              <button
                className="join-course-button"
                onClick={(e) => {
                  e.stopPropagation();
                  openPaymentModal(course);
                }}
              >
                {course.coursePrice === 0
                  ? "Join Free"
                  : `Join $${course.coursePrice?.toFixed(2)}`}
              </button>
            )}

            <FaShare
              className="courses-page-share-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleShare(course.courseTitle);
              }}
            />
          </div>
        </div>
      </div>
    ));
  };

  // ===== RENDER =====
  return (
    <div className="courses-page">
      {/* Top Nav */}
      <TopNavBar
        toggleSidebar={toggleSidebar}
        onLogout={onLogout}
        onCreateCourse={handleCreateCourse}
      />

      {/* Sidebar */}
      <CommunitySidebar isOpen={sidebarOpen} />

      {/* Main Content */}
      <div className={`courses-page-content ${sidebarOpen ? "sidebar-open" : ""}`}>
        


        {/* Filters */}
        <div className="courses-page-filters">
          {/* Sort Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick("sort")}>
              Sort by: {sortFilter.charAt(0).toUpperCase() + sortFilter.slice(1)}
              <FaCaretDown
                className={`dropdown-icon ${showDropdown === "sort" ? "open" : ""}`}
              />
            </div>
            {showDropdown === "sort" && (
              <div className="filter-options">
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("sort", "newest")}
                >
                  Newest
                </div>
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("sort", "oldest")}
                >
                  Oldest
                </div>
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("sort", "rating")}
                >
                  By Rating
                </div>
              </div>
            )}
          </div>

          {/* Rating Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick("rating")}>
              Rating: {ratingFilter || "All"}
              <FaCaretDown
                className={`dropdown-icon ${showDropdown === "rating" ? "open" : ""}`}
              />
            </div>
            {showDropdown === "rating" && (
              <div className="filter-options">
                {ratings.map((rating) => (
                  <div
                    key={rating}
                    className="filter-option"
                    onClick={() => handleFilterSelect("rating", rating.toString())}
                  >
                    {rating} Stars
                  </div>
                ))}
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("rating", "")}
                >
                  All Ratings
                </div>
              </div>
            )}
          </div>

          {/* Topic Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick("topic")}>
              Topic: {topicFilter || "All"}
              <FaCaretDown
                className={`dropdown-icon ${showDropdown === "topic" ? "open" : ""}`}
              />
            </div>
            {showDropdown === "topic" && (
              <div className="filter-options">
                {topics.map((topic) => (
                  <div
                    key={topic}
                    className="filter-option"
                    onClick={() => handleFilterSelect("topic", topic)}
                  >
                    {topic}
                  </div>
                ))}
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("topic", "")}
                >
                  All Topics
                </div>
              </div>
            )}
          </div>

          {/* Enrolled Filter */}
          <div className="filter-dropdown">
            <div className="filter-label" onClick={() => handleFilterClick("enrolled")}>
              Enrolled: {enrolledFilter || "All"}
              <FaCaretDown
                className={`dropdown-icon ${showDropdown === "enrolled" ? "open" : ""}`}
              />
            </div>
            {showDropdown === "enrolled" && (
              <div className="filter-options">
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("enrolled", "yes")}
                >
                  Yes
                </div>
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("enrolled", "no")}
                >
                  No
                </div>
                <div
                  className="filter-option"
                  onClick={() => handleFilterSelect("enrolled", "")}
                >
                  All
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* TOP PAGINATION MENU */}
        <PaginationMenu currentPage={page} maxPages={maxPages} setCurrentPage={setPage} />

        {/* Course List */}
        <div className="courses-page-course-list">
          {renderCourseList()}
          {isLoading && <div className="loading">Loading...</div>}
          {error && <div className="error">{error}</div>}
        </div>

        {/* BOTTOM PAGINATION MENU */}
        <PaginationMenu currentPage={page} maxPages={maxPages} setCurrentPage={setPage} />
      </div>

      {/* === JOIN COURSE MODAL === */}
      {showJoinModal && selectedCourse && (
        <div className="join-course-modal">
          <div className="join-course-modal-content">
            <button className="close-button" onClick={() => setShowJoinModal(false)}>
              ×
            </button>
            <h2>{`You have successfully joined ${selectedCourse.courseTitle}`}</h2>
            <p>
              <strong>Instructor:</strong> {selectedCourse.creatorID.username}
            </p>
            <p>
              <strong>Category:</strong> {selectedCourse.courseCategory}
            </p>
            <p>
              <strong>Description:</strong> {selectedCourse.courseDescription}
            </p>
            <p>
              <strong>Price:</strong> ${selectedCourse.coursePrice.toFixed(2)}
            </p>
            <button className="confirm-button" onClick={() => setShowJoinModal(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* === PAYMENT MODAL === */}
      {showPaymentModal && selectedCourse && (
        <div className="payment-modal">
          <div className="payment-modal-content">
            <button className="close-button" onClick={() => setShowPaymentModal(false)}>
              ×
            </button>
            <h2>{`Purchase ${selectedCourse.courseTitle}`}</h2>
            <form onSubmit={handlePaymentSubmit}>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": { color: "#aab7c4" },
                    },
                    invalid: { color: "#9e2146" },
                  },
                }}
              />
              <button type="submit" disabled={!stripe || !elements || isProcessing}>
                {isProcessing
                  ? "Processing..."
                  : `Pay $${selectedCourse.coursePrice.toFixed(2)}`}
              </button>
            </form>
          </div>
        </div>
      )}

      {/* === ROLE MODAL === */}
      {showRoleModal && (
        <div className="courses-page-role-modal">
          <div className="courses-page-role-modal-content">
            <button className="courses-page-close-button" onClick={() => setShowRoleModal(false)}>
              ×
            </button>
            <h2>Create</h2>
            <div className="courses-page-role-options">
              <div className="courses-page-role-option">
                <h3>Educator</h3>
                <button
                  onClick={() => redirectToSignup("educator")}
                  className="courses-page-create-account-button"
                >
                  Create an account
                </button>
              </div>
              <div className="courses-page-role-option">
                <h3>Organization</h3>
                <button
                  onClick={() => redirectToSignup("organization")}
                  className="courses-page-create-account-button"
                >
                  Create an account
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default CoursesPage;
