import React, { useState } from "react";
import { FaUpload } from "react-icons/fa";

function IntroVideoStep({
  introVideo,
  introVideoTitle,
  introDuration,
  onIntroTitleChange,
  onIntroVideoChange,
  onPrevious,
  onNext,
}) {
  const [isUploading, setIsUploading] = useState(false);

  const handleVideoUpload = async (e) => {
    setIsUploading(true);
    await onIntroVideoChange(e);
    setIsUploading(false);
  };

  const handleRemoveVideo = () => {
    onIntroVideoChange("remove");
  };

  return (
    <div className="border border-gray-300 rounded-lg bg-white p-6 mb-6">
      <h2 className="text-left text-lg font-bold text-gray-800">
        Introduction Video
      </h2>
      <h3 className="text-sm text-gray-800 mb-2">
        Let's add an introduction video to your course
      </h3>

      <div className="mb-2">
        <label className="block text-xs font-medium text-gray-700">
          Introduction Title
        </label>
        <input
          type="text"
          placeholder="Course Introduction"
          value={introVideoTitle}
          onChange={(e) => onIntroTitleChange(e.target.value)}
          className="border text-xs border-gray-300 text-gray-500 rounded-md p-2 w-full"
        />
      </div>

      <div>
        <label className="block text-xs font-medium text-gray-700">
          Introduction Video{" "}
          <span className="text-green-600 text-xs ml-1">(Free Preview)</span>
        </label>
        <div className="my-2">
          <label
            htmlFor="intro-video-upload"
            className={`flex flex-col items-center justify-center border-2 border-dashed rounded-lg p-5 ${
              isUploading
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-gray-100 hover:bg-gray-200 cursor-pointer"
            } transition min-h-[120px]`}
          >
            {isUploading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-6 w-6 text-blue-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                  ></path>
                </svg>
                <span className="ml-2 text-sm text-gray-600">Uploading...</span>
              </div>
            ) : introVideo ? (
              <div className="text-center">
                <p className="text-sm text-gray-600">{introVideo.name}</p>
                <p className="text-xs text-gray-500 mt-1">
                  Duration: {introDuration} min
                </p>
                <button
                  onClick={handleRemoveVideo}
                  className="mt-2 text-red-500 text-sm hover:underline"
                >
                  Remove Video
                </button>
              </div>
            ) : (
              <>
                <FaUpload size={24} className="mb-2 text-gray-400" />
                <p className="text-sm text-center text-gray-600">
                  Add Introduction Video
                </p>
                <p className="text-xs text-center text-gray-500 mt-1">
                  This video will be available as a free preview
                </p>
              </>
            )}
            <input
              type="file"
              id="intro-video-upload"
              accept="video/mp4,video/quicktime,video/x-msvideo"
              onChange={handleVideoUpload}
              className="hidden"
              disabled={isUploading} // Disable input during upload
            />
          </label>
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={onPrevious}
          className="bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition"
          disabled={isUploading} // Disable back button during upload
        >
          Back
        </button>
        <button
          onClick={onNext}
          className={`bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition ${
            isUploading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isUploading} // Disable next button during upload
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default IntroVideoStep;
