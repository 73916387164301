import React, { useState } from "react";
import { FaUpload } from "react-icons/fa";

function BasicInfoStep({
  courseTitle,
  courseDescription,
  courseCategory,
  courseThumbnailURL,
  onTitleChange,
  onDescriptionChange,
  onCategoryChange,
  onThumbnailChange,
  onNext,
}) {
  const [isUploading, setIsUploading] = useState(false);

  const handleThumbnailUpload = async (e) => {
    setIsUploading(true);
    await onThumbnailChange(e); // Pass the event to onThumbnailChange
    setIsUploading(false);
  };

  const handleRemoveThumbnail = () => {
    onThumbnailChange("remove");
  };

  return (
    <div className="flex flex-col course-details-section border border-gray-300 rounded-lg bg-white p-6 mb-6">
      <h2 className="text-left text-1xl font-bold text-gray-800">
        Basic Information
      </h2>
      <h3 className="text-sm text-gray-800 mb-1">
        Let's add some basic information about your course
      </h3>

      <div className="course-main-info flex flex-col gap-2 mb-6">
        <div>
          <label className="text-xs font-medium text-gray-700 mb-2">
            Course Title
          </label>
          <input
            type="text"
            placeholder="Course Title"
            value={courseTitle}
            onChange={(e) => onTitleChange(e.target.value)}
            className="border text-xs border-gray-300 rounded-md p-2 w-full"
          />
        </div>
        <div>
          <label className="text-xs font-medium text-gray-700 mb-2">
            Course Category
          </label>
          <input
            type="text"
            placeholder="Category"
            value={courseCategory}
            onChange={(e) => onCategoryChange(e.target.value)}
            className="border text-xs border-gray-300 rounded-md p-2 w-full"
          />
        </div>
        <div className="description-section">
          <label className="text-xs font-medium text-gray-700 mb-2">
            Course Description
          </label>
          <textarea
            placeholder="Description"
            value={courseDescription}
            onChange={(e) => onDescriptionChange(e.target.value)}
            className="border text-xs border-gray-300 rounded-md p-2 w-full h-40 resize-vertical"
          />
        </div>
      </div>

      <div className="thumbnail-section flex-1 flex flex-col">
        <h3 className="text-lg font-semibold text-gray-800 mb-1">
          Course Thumbnail
        </h3>
        <div className="course-creation-upload-section flex-1 flex my-1">
          <label
            htmlFor="course-thumbnail-upload"
            className={`course-creation-upload-placeholder flex items-center justify-center w-full border-2 border-dashed rounded-lg p-3 ${
              isUploading
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-gray-100 hover:bg-gray-200 cursor-pointer"
            } transition`}
          >
            {isUploading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-6 w-6 text-blue-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                  ></path>
                </svg>
                <span className="ml-2 text-sm text-gray-600">Uploading...</span>
              </div>
            ) : courseThumbnailURL ? (
              <div className="relative w-full h-auto">
                <img
                  src={courseThumbnailURL}
                  alt="Course Thumbnail"
                  className="w-full h-auto rounded"
                />
                <button
                  type="button"
                  onClick={handleRemoveThumbnail}
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 text-xs hover:bg-red-600 transition"
                >
                  Remove
                </button>
              </div>
            ) : (
              <>
                <FaUpload size={24} className="mb-2 text-gray-400" />
                <p className="text-sm text-center text-gray-600">
                  Upload thumbnail
                </p>
              </>
            )}
            <input
              type="file"
              id="course-thumbnail-upload"
              accept="image/jpeg,image/png,image/gif"
              onChange={handleThumbnailUpload}
              className="hidden"
              disabled={isUploading} // Disable during upload
            />
          </label>
        </div>
      </div>

      <button
        onClick={onNext}
        className="mt-4 bg-[#002855] text-white rounded-md px-4 py-2 hover:bg-[#001f40] transition ml-auto"
      >
        Next
      </button>
    </div>
  );
}

export default BasicInfoStep;
