import React from "react";
import "./LoginSignupPage.css";
// import GoogleSignIn from './Login/GoogleSignIn';
import TextSpinner from "./Login/TextSpinner/TextSpinner";

const LoginSignupPage = ({ onLogin, onSignup }) => {
  return (
    <div className="login-signup-page">
      <div className="content-container">
        <div className="left-section">
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source
                src={require("../../assets/ARCHIAnimation.mp4")}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="welcome-message">
            {/* <h2>Welcome to Archi Studies!</h2> */}
            <TextSpinner
              content={[
                "Welcome to",
                "Bienvenido a",
                "Bienvenue à",
                "Willkommen bei",
                "欢迎来到",
                "ようこそ",
                "में आपका स्वागत है",
                "Chào mừng đến với",
              ]}
              intervalTime={3000}
            />
            <p>
              We are building an online education platform for people around the
              world to connect and teach each other. Find your friends, study
              with them, find a mentor, learn from them, and find a job! We hope
              you enjoy, study hard, learn lots!
            </p>
          </div>
        </div>
        <div className="logo-and-buttons">
          <div className="auth-buttons-container">
            {/* <GoogleSignIn />
            <div className="divider">
              <span>or</span>
            </div> */}
            <button className="signup-button" onClick={onSignup}>
              Create account
            </button>
            <div className="signin-container">
              <p>Already have an account?</p>
              <button onClick={onLogin} className="signin-button auth-button">
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignupPage;
